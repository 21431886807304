$(document).ready(function () {
    // Fancybox
    Fancybox.bind('[data-fancybox]');

    Fancybox.bind('[data-fancybox="video"]', {
        aspectRatio: true,
        mainClass: 'f-video',
    });

    // Sliders
    $('.slider').each(function () {
        var $slider = $(this);
        var slidesCount = $slider.children().length;
        $slider.attr('slides-count', slidesCount);

        var ID = $slider.data('slider-id');

        var $navigation = $(`[data-slider-navigation-id="${ID}"]`);

        $(`[data-slider-navigation-id="${ID}"]`)
            .find('.slider-navigation__slide-line-current')
            .width(100 / slidesCount + '%');

        $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $navigation.find('.slider-navigation__slide-number_current').text(nextSlide + 1);
            var val = ($navigation.find('.slider-navigation__slide-line').width() / slidesCount) * nextSlide + 'px';

            $navigation.find('.slider-navigation__slide-line-current').css({
                '-webkit-transform': 'translateX(' + val + ')',
                '-moz-transform': 'translateX(' + val + ')',
                '-ms-transform': 'translateX(' + val + ')',
                '-o-transform': 'translateX(' + val + ')',
                transform: 'translateX(' + val + ')',
            });
        });
    });

    // Economy
    $('.economy-slider').slick({
        dots: false,
        slidesToShow: 2,
        arrows: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                },
            },
        ],
    });

    $('.economy-slider__arrow-prev').click(function () {
        $('.economy-slider').slick('slickPrev');
    });

    $('.economy-slider__arrow-next').click(function () {
        $('.economy-slider').slick('slickNext');
    });

    // Tabs
    $('.help__tab').click(function () {
        if (!$(this).hasClass('help__tab_active')) {
            var $index = $(this).index();

            $('.help__tab').removeClass('help__tab_active');
            $(this).addClass('help__tab_active');

            $('.help-item').removeClass('help-item_active').hide();
            $('.help-item').eq($index).addClass('help-item_active').fadeIn();
        }
    });

    $('.help-item__arrow-next').click(function () {
        var $index = $('.help-item_active').index();

        $('.help-item').removeClass('help-item_active').hide();
        $('.help__tab').removeClass('help__tab_active');

        if ($('.help-item').eq($index + 1).length > 0) {
            $('.help-item')
                .eq($index + 1)
                .addClass('help-item_active')
                .fadeIn();
            $('.help__tab')
                .eq($index + 1)
                .addClass('help__tab_active');
        } else {
            $('.help-item').eq(0).addClass('help-item_active').fadeIn();
            $('.help__tab').eq(0).addClass('help__tab_active');
        }
    });

    $('.help-item__arrow-prev').click(function () {
        var $index = $('.help-item_active').index();

        $('.help-item').removeClass('help-item_active').hide();
        $('.help__tab').removeClass('help__tab_active');

        if ($('.help-item').eq($index - 1).length > 0 && $index - 1 >= 0) {
            $('.help-item')
                .eq($index - 1)
                .addClass('help-item_active')
                .fadeIn();
            $('.help__tab')
                .eq($index - 1)
                .addClass('help__tab_active');
        } else {
            $('.help-item')
                .eq($('.help-item').length - 1)
                .addClass('help-item_active')
                .fadeIn();
            $('.help__tab')
                .eq($('.help-item').length - 1)
                .addClass('help__tab_active');
        }
    });

    // Tasks
    $('.tasks-cases')
        .slick({
            dots: false,
            arrows: false,
            fade: true,
            adaptiveHeight: true,
        })
        .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('.tasks__tab').removeClass('tasks__tab_active');
            $('.tasks__tab').eq(nextSlide).addClass('tasks__tab_active');
        });

    $('.tasks__tab').click(function () {
        if (!$(this).hasClass('tasks__tab_active')) {
            var $index = $(this).index();

            $('.tasks__tab').removeClass('tasks__tab_active');
            $(this).addClass('tasks__tab_active');

            $('.tasks-cases').slick('slickGoTo', $index);
        }
    });

    // Toggler
    $('.feedback-toggler').click(function () {
        var checked = $(this).attr('data-checked') === 'true';
        $(this).attr('data-checked', !checked);

        $('.feedback-content__item').hide();
        $('.feedback-content__item')
            .eq(checked ? 0 : 1)
            .fadeIn();
    });

    // Questions
    $('.questions-item').click(function () {
        if (!$(this).hasClass('questions-item_active')) {
            $(this).addClass('questions-item_active');
            $(this).find('.questions-item__body').slideDown();
        } else {
            $(this).removeClass('questions-item_active');
            $(this).find('.questions-item__body').slideUp();
        }
    });

    // Promo
    $('.promo-slider').slick({
        dots: false,
        arrows: false,
        fade: true,
    });

    // Fancybox
    $('body').on('click', '.open-popup', function (e) {
        e.preventDefault();

        var el = $(this).data('popup');
        var $this = $(this);

        $('.popup-' + el)
            .find('[name="form-desc"]')
            .val($(this).data('form-desc'));

        var popup = '#popup-' + el;

        Fancybox.show(
            [
                {
                    src: '#popup-' + el,
                    type: 'inline',
                },
            ],
            {
                padding: 0,
                compact: false,
                centred: true,
                hideScrollbar: true,
                on: {
                    done: (fancybox, eventName) => {
                        if ($(popup).find('.service-slider'))
                            if (!$(popup).find('.service-slider').hasClass('slick-initialized'))
                                $(popup).find('.service-slider').slick({
                                    dots: false,
                                    centerMode: true,
                                    infinite: true,
                                    variableWidth: true,
                                    prevArrow: '<div class="slider__arrow slider__arrow-prev"></div>',
                                    nextArrow: '<div class="slider__arrow slider__arrow-next"></div>',
                                    arrows: true,
                                });
                    },
                },
            }
        );
    });

    // Lazyload
    var lazyLoadInstance = new LazyLoad({});

    // Menu
    $('.header-menu__open').click(function () {
        $this = $(this);

        if ($this.hasClass('header-menu__open_opened')) {
            $('.header-menu').hide();
            $this.removeClass('header-menu__open_opened');
            $('.header').removeClass('header_menu_opened');
        } else {
            $('.header-menu').fadeIn();
            $('.header').addClass('header_menu_opened');
            $this.addClass('header-menu__open_opened');
        }
    });

    // Forms
    $('form').submit(function () {
        return false;
    });

    function getFormObject(formArray) {
        var obj = {};
        $.each(formArray, function (i, pair) {
            var cObj = obj,
                pObj,
                cpName;
            $.each(pair.name.split('.'), function (i, pName) {
                pObj = cObj;
                cpName = pName;
                cObj = cObj[pName] ? cObj[pName] : (cObj[pName] = {});
            });
            pObj[cpName] = pair.value;
        });
        return obj;
    }

    function submitForm(form) {
        if ($(form).hasClass('no-valid')) {
            return false;
        }

        var $button = $(form).find('.form__submit');

        if ($button.attr('disabled')) return false;

        $button.attr('disabled', true);

        var formData = getFormObject($(form).serializeArray());

        axios({
            method: 'post',
            url: '/sender.php',
            data: formData,
            config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
            withCredentials: true,
        })
            .then(function (response) {
                $(form).find('input[type=text], textarea').val('');

                Fancybox.close();
                Fancybox.show([{ src: '#popup-thanks', type: 'inline' }]);

                $button.attr('disabled', false);

                ym(94999651, 'reachGoal', 'send-form');
            })
            .catch(function (response) {
                $button.attr('disabled', false);
            });
    }

    $('form').each(function () {
        $(this).validate({
            rules: {
                name: {
                    required: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                phone: {
                    required: true,
                },
            },
            messages: {
                name: 'Обязательное поле',
                phone: 'Обязательное поле',
            },
            submitHandler: function (form) {
                submitForm(form);
            },
        });
    });

    $('[name="phone"]').inputmask({
        mask: '+7 (999) 999-99-99',
        greedy: false,
        showMaskOnFocus: true,
        clearIncomplete: true,
    });

    new WOW().init();
});